// debug.js
function dbg(...args) {
    let formattedArgs = [];
    let tabs = '\t\t\t';
    let date = new Date();
    let timestamp = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
    formattedArgs.push(timestamp, tabs);

    let stack = new Error().stack.split('\n');
    let callerInfo = stack[2].trim();
    let callerInfoClean = callerInfo.split(' ')[1].replace(/[()]/g, '').split('/')[0];

    formattedArgs.push(callerInfoClean, tabs);
  
    args.forEach((arg, index) => {
        if (index !== 0) {
            formattedArgs.push(tabs);
        }
        formattedArgs.push(arg);
    });

    console.log(...formattedArgs);
}

export default dbg;