<template>
  <nav class="navbar">
    <img class="logo" src="../../public/minexLogo.png" alt="">
    <div class="nav-items">
      <img class="user-icon" src="../../public/userIcon.png" alt="">
      <span class="nav-text">{{ $store.state.loggedUser }}</span>
      <div class="nav-button" v-if="$store.state.loggedUser != ''" @click="logoutUser()">{{ $t("navbar.signStatus") }}</div>
  <div class="dropdown" @click="toggleDropdown">
    {{ this.$i18n.locale }}
    <div class="dropdown-content" v-show="isDropdownOpen">
      <a href="#" v-for="(lang, i) in langs" :key="`Lang${i}`" @click="updateLanguage(lang)">
        {{ lang }}
      </a>
    </div>
  </div>
    </div>
  </nav>
  <transition>
    <info v-if="($store.state.msg.length > 0)" />
  </transition>
</template>

<script>
import dbg from '@/debug';
import EnvProvider from 'jvjr-docker-env';
import { useCookies } from 'vue3-cookies';
import Info from './Info.vue'

export default {
  components: {
    Info
  },
  data() {
    return {
      langs: ["sk", "en"],
      isDropdownOpen: false,
      
    }
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods: {
    updateLanguage(lang) {
      dbg("lang: ", lang)
      this.$i18n.locale = lang;
      dbg(this.$i18n.locale);
      localStorage.setItem("lang", this.$i18n.locale);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    logoutUser() {
      console.log('Logging out user');
      this.axios.get(EnvProvider.value('API_AUTH') + this.$root.API_LOGOUT,
      ).then((result) => {
        console.log(result.data);
        console.log('Response data: ' + result.data);
        if (result.status == 200) {
          this.cookies.remove('userName', null, EnvProvider.value('HOST_DOMAIN'));
          this.cookies.remove('csrfToken');
          this.$store.commit('setLoggedUser', '');
          this.$store.commit('pushMsg', { type: 'ok', text: this.$t("login.logoutSuccess") });
          this.$router.push({ name: 'login' })
        } else {
          // this.$store.commit('pushMsg', { type: 'error', text: this.$t("StoreCommits.processingFailed") })
          console.log("Failed to logout");
        }
      }).catch((e) => {
        console.log(e);
        // setResponse("This is an error from catch()");
      });
      console.log('After log out')

    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }
  },
  watch: {
        '$store.state.msg.length': {
            handler(val,oldVal){
                if( val > oldVal) {
                    setTimeout(() => { this.$store.commit('popMsg'); }, 7000);
                }
            },
            deep: true
        }
  },
}
</script>

<style>
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 47px;
    padding-block: 0.5rem;
    background-color: #2156a4;
    color: white;
}

.logo {
    width: 170px;
    margin-left: 7%;
}

.user-icon {
  width: 30px;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 2%;
}

.nav-text, .nav-button, .dropdown {
  cursor: pointer;
}

.nav-button {
  border: none;
  color: white;
  font-size: 16px;
  background: #4e78b6;
  padding: 0.6rem;
  border-radius: 15px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 62px;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    /* padding: 12px 16px; */
    z-index: 1;
    color: black;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
</style>
