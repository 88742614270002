import { createStore } from "vuex";

export default createStore({
  state: {
    msg: [],
    userToken: null,
    loggedUser: ''
  },
  getters: {},
  mutations: {
    setLoggedUser(state, loggedUser) {
      state.loggedUser = loggedUser
    },
    pushMsg (state, data){
      state.msg.push({
        type: data.type, 
        text: data.text
      })
      setTimeout(() => {
        state.msg.shift()
      }, 7000)
    },
    popMsg (state) {
      state.msg.shift()
    },
    clearMsg (state) {
      state.msg.length = 0
    }
  },
  actions: {},
  modules: {},
});
