import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import EnvProvider from "jvjr-docker-env";
import { useCookies } from "vue3-cookies";

// all localization JSONs
import messages from "./lang";

// css used for reseting
// import "reset-css";

const { cookies } = useCookies();

const i18n = createI18n({
	locale: "sk",
	fallbackLocale: "en",
	messages,
	globalInjection: true,
});

// no cache in response from axios
axios.defaults.headers = {
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

if (EnvProvider.value("ENV") === "DEV") {
	// FOR DEVELOPMENT ONLY
	axios.defaults.withCredentials = true;
}

// Add CSRF token in header if logged in
axios.interceptors.request.use( function (config) {
	// console.log('CSRF: token in header')
	let csrf = cookies.get('csrfToken')
	let user = cookies.get('userName')
	// console.log(user)
	// console.log(EnvProvider.value('ENV'))
	if(EnvProvider.value('LOCAL') === 'true' && EnvProvider.value('ENV') === 'DEV'){
		console.log('DEV CSRF: token in header')
		config.headers['x-csrf-token'] = 'dev_bypass';
		csrf = 'dev_bypass'
	}
  
	if(user != ''){   
		config.headers['x-csrf-token'] = csrf;
	}
  
	return config;
  },
  error => {
	console.log(error)
	return Promise.reject(error);
  }
  );


const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(VueAxios, axios);
app.use(router);

app.mount("#app");
export { i18n };

