import { createRouter, createWebHistory } from "vue-router";
import SelectProduct from "../views/SelectProduct.vue";
import Login from "../views/Login.vue";
import store from "../store";
import EnvProvider from 'jvjr-docker-env';
import { useCookies } from "vue3-cookies";
import axios from 'axios';

const { cookies } = useCookies();

const routes = [
  {
    path: "/",
    name: "selectProduct",
    component: SelectProduct,
    meta:{requiresAuth:true},
  },


  {
    path: "/login-page",
    name: "login",
    component: Login,
    meta:{requiresAuth:false},
  },
  
  {
    path: '/*',
    redirect: '/selectProduct'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

async function auth(){
  console.log('Startin auth')
  try {
    if (EnvProvider.value('API_AUTH') == undefined) return false;
    const response = await axios.get(EnvProvider.value('API_AUTH') + '/auth', {withCredentials: true});
    if (response.status === 200){
      if (response.data.logged_in){
        console.log('true')
        return true
      } else {
        console.log('false')
        return false
      }
    } else {
        console.log('false')
        return false
    }
  } catch(err){
    console.log(err)
    return false
  }
}

function setLoggedUser(){
  let user = cookies.get('userName')
  if(user != null){
    cookies.set('userName', user, EnvProvider.value('COOKIE_DURATION')+'s', null, EnvProvider.value('HOST_DOMAIN'))
    store.commit('setLoggedUser', user)
  }
  else {
    store.commit('setLoggedUser', '')
    console.log('Failed to set username from cookie')
  }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.name === 'login') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    setLoggedUser()
    next() // i'm logged in. carry on
  } else {
    next({ name: 'login' }) // always put your redirect as the default case
  }
})

export default router;
