<template>
  <div class="login">
    <img src="../../public/minexLogo1.png" class="login-img" />
    <div class="form-group">
      <label class="form-label" for="username">{{
        this.$t("login.username")
      }}</label>
      <input
        name="username"
        type="text"
        class="form-input"
        id="username"
        v-model="userName"
        @keyup.enter="authUser()"
      />
    </div>
    <div class="form-group">
      <label class="form-label" for="password">{{
        this.$t("login.password")
      }}</label>
      <input
        name="password"
        type="password"
        class="form-input"
        id="password"
        v-model="password"
        @keyup.enter="authUser()"
      />
    </div>
    <button @click="authUser" class="login-submit">
      {{ this.$t("login.button") }}
    </button>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import EnvProvider from "jvjr-docker-env";
import store from "../store";
import MsgGen from "../scripts/MsgGen.js";

export default {
  name: "Login",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      userName: "",
      password: "",
      message: ""
    };
  },
  methods: {
    authUser() {
      // read cookie, if no cookie, then login, otherwise cookie!!
      if (this.userName == "" || this.password == "") {
        return null;
      }
      // console.log(EnvProvider.value('API_AUTH'))
      // console.log(EnvProvider.value('URL_HOST'))
      // console.log(EnvProvider.value('URL_ANONYMA'))
      // console.log(EnvProvider.value('URL_SEARCH'))
      // console.log(EnvProvider.value('URL_SIGNER'))
      // console.log(EnvProvider.value('ENV'))

      let formData = new FormData();
      formData.append("username", this.userName);
      formData.append("password", this.password);

      this.axios
        .post(EnvProvider.value("API_AUTH") + this.$root.API_LOGIN, formData)
        .then((result) => {
          console.log(result);
          this.message = null;
          var csrf = this.cookies.get("csrfToken");

          // Check if cookies are set (only checks CSRF cookie as the other one is httpOnly)
          if (!csrf) {
            console.log("CSRF token is not set.");
            store.commit("pushMsg", {
              type: "error",
              text: this.$t("login.loginProblem"),
            });
          } else {
            // Set logged in user
            console.log("setting user cookie");
            this.cookies.set(
              "userName",
              this.userName.split("@")[0],
              EnvProvider.value('COOKIE_DURATION')+'s',
              null,
              EnvProvider.value("HOST_DOMAIN")
            );
            //console.log(this.cookies.get("userName"));
            //localStorage.setItem('userName', this.userName.split('@')[0])
            store.commit("setLoggedUser", this.userName.split("@")[0]);
            store.commit("pushMsg", {
              type: "ok",
              text: this.$t("login.loginSuccess"),
            });
            
            // Set cookie for license data
            if (result.data['license_info']){
              this.cookies.set(
                "licenseData",
                result.data['license_info'],
                EnvProvider.value('COOKIE_DURATION')+'s',
                null,
                EnvProvider.value('HOST_DOMAIN')
              );
            }
            
            // Generate and push license warning messages
            let msgs = MsgGen(result.data, EnvProvider.value('DAYS_LEFT'), EnvProvider.value('DOCS_LEFT'));
            for (let i = 0; i < msgs.length; i++) {
              if (msgs[i].type !== null) {
                store.commit("pushMsg", msgs[i]);
              }
            }

            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);

          if (error.response) {

            if(error.response.status == 401){
              store.commit("pushMsg", {
                type: "error",
                text: this.$t("login.loginFailure"),
              });
            }

            if(error.response.status == 403){
              let invalid_license = error.response.data["invalid_license"];
              if (invalid_license['days_left']) {
                store.commit("pushMsg", {
                  type: "error",
                  text: this.$t("login.licenseExpiredDays")
                });
              }
              if (invalid_license['users_left']) {
                store.commit("pushMsg", {
                  type: "error",
                  text: this.$t("login.licenseExpiredUsers")
                });
              }
              if (invalid_license['docs_left']) {
                store.commit("pushMsg", {
                  type: "error",
                  text: this.$t("login.licenseExpiredDocs")
                });
              }
            }

            if(error.response.status == 409){
              store.commit("pushMsg", {
                type: "error",
                text: this.$t("login.alreadyLoggedIn")
              });
            }

          } else {
            store.commit("pushMsg", {
              type: "error",
              text: this.$t("login.loginProblem"),
            });
          }

          return Promise.reject(error);
        });
    },
  },
};
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input {
  border-radius: none;
}

.login {
  position: absolute;
  width: 300px;
  padding-inline: 50px;
  padding-block: 30px;
  display: flex;
  top: 50%;
  left: 50%;
  background-color: #e9e9e9;
  border-radius: 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-img {
  width: 200px;
  margin-bottom: 20px;
}

.login-title {
  font-size: 3em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.form-group {
  position: relative;
  display: flex;
  margin-bottom: 1em;
}

.form-label {
  color: #9d9d9d;
  font-weight: 500;
  font-size: 17px;
  position: absolute;
  margin-bottom: 0.6em;
}

.form-input {
  color: black;
  background-color: #e9e9e9;
  font-size: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #9d9d9d;
  padding: 1.2em 0 0 0;
  transition: all 300ms;
  height: 40px;
}

.form-input:focus,
.form-input:active {
  outline: none;
  padding-top: 2em;
}

.login-submit {
  margin-top: 1.8em;
  font-family: inherit;
  font-size: 17px;
  width: 160px;
  height: 55px;
  border-radius: 15px;
  border: none;
  background-color: #2156a4;
  color: #f4f4f4;
  margin-left: auto;
  font-weight: 600;
  cursor: pointer;
  transition: all 300ms;
}

.login-submit:hover {
  opacity: 0.9;
}
</style>
