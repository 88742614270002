import {i18n} from '../main.js';

function daysLeft(data, warningAmount) {
    const leftDays = data["license_info"]["days_left"];
    if (leftDays === null) {
        return {
            type: null,
            text: `No license`,
        };
    } else {
        let lang = i18n.global.locale

        if (leftDays <= warningAmount) {
            let warnText = i18n.global.t('login.licenseExpiring') + ' ' + leftDays + ' '
            if (lang === "en") {
                if (leftDays === 1) {
                    warnText += i18n.global.t('login.licenseExpiringDay')
                } else {
                    warnText += i18n.global.t('login.licenseExpiringDays')
                }
            } else if (lang === "sk") {
                if (leftDays === 1) {
                    warnText += i18n.global.t('login.licenseExpiringDay')
                }
                if (leftDays > 1 && leftDays < 5) {
                    warnText += i18n.global.t('login.licenseExpiringDaysB')
                } else {
                    warnText += i18n.global.t('login.licenseExpiringDays')
                }
            }
            return {
                type: "warn",
                text: warnText
            };
        } else {
            return {
                type: null,
                text: `No warning needed`,
            };
        }
    }
}

function docsLeft(data, warningAmount) {
    const docsLeft = data["license_info"]["docs_left"];
    if (docsLeft === null) {
        return {
            type: null,
            text: `No license`,
        };
    } else {
        let lang = i18n.global.locale

        if (docsLeft <= warningAmount) {
            let warnText = i18n.global.t('login.licenseExpiring') + ' ' + docsLeft + ' '
            if (lang === "en") {
                if (docsLeft === 1) {
                    warnText += i18n.global.t('login.licenseExpiringDoc')
                } else {
                    warnText += i18n.global.t('login.licenseExpiringDocs')
                }
            } else if (lang === "sk") {
                if (docsLeft === 1) {
                    warnText += i18n.global.t('login.licenseExpiringDoc')
                }
                if (docsLeft > 1 && docsLeft < 5) {
                    warnText += i18n.global.t('login.licenseExpiringDocsB')
                } else {
                    warnText += i18n.global.t('login.licenseExpiringDocs')
                }
            }
            return {
                type: "warn",
                text: warnText
            };
        } else {
            return {
                type: null,
                text: `No warning needed`,
            };
        }
    }
}

function ValidMsg(data, warningAmountDays, warningAmountDocs) {
    const msg = [];
    msg.push(daysLeft(data, warningAmountDays));
    msg.push(docsLeft(data, warningAmountDocs));
    return msg;
}

export default ValidMsg;

